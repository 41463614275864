import React, { useState, useLayoutEffect, MouseEvent, useEffect, useRef } from 'react'
import * as api from '../../../services/api'
import { useItemsStore } from "../../../contexts/zustand/onboarding"
import { APIOnboarding } from '../../../services/api'
import { useNavigate } from "react-router-dom"
import { useAuth } from "../../../hooks/useAuth"
import { useCore } from "../../../hooks/useCore"
import { TypeColumn } from "../../../types/onboarding"

import ModalDelete from "../../../components/ModalDelete"

import FormControl from "@mui/material/FormControl"
import Input from "@mui/material/Input"
import InputAdornment from "@mui/material/InputAdornment"
import Typography from "@mui/material/Typography"

import SearchIcon from '@mui/icons-material/Search'
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';

import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import ArrowRightAltIcon from '@mui/icons-material/ArrowRightAlt';
import ListItemIcon from '@mui/material/ListItemIcon';
import FilterAltOffIcon from '@mui/icons-material/FilterAltOff';

import { Button, Grid, MenuItem, Select, SelectChangeEvent, Menu, Box, Tooltip } from "@mui/material"
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';

import { Paginator } from "../../../components/Paginator"
import SendOnboarding from "../SendOnboarding"

import { LoadingPage } from "../../../components/LoadingPage"

import IconButton from '@mui/material/IconButton';
import MoreVertIcon from '@mui/icons-material/MoreVert';

import ProtectRoutesOnboarding from '..'

import { toast, ToastContainer } from "react-toastify"

import ptBR from 'date-fns/locale/pt-BR';

import DatePicker, { registerLocale } from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import './index.css'

type ItemContent = {
    user: {
        email: string;
    };

    id: string;
    code: string;
    data: { label: string; value: string }[];
    created_at: string;
    status: string;
    layout: {
        responsible_user: string;
        name_model: string;
        name_onboarding: string;
        logo: string;
        primary_color: string;
        second_color: string;
        text_color: string;
        button_text_color: string;
        third_color: string;
        navbar_background_color: string;
        card_shadow_color: string;
        baseboard_color: string;
        baseboard_text_color: string;
        card_color: string;
        card_text_color: string;
        template_email: string;
    };

};

type ResponseSignatoryRow = {
    idSignatory: string;
    users: string,
    name_client: string,
    name_model: string,
    date: string,
    status: string,
    checkBox: string;
}

type MoreOptionsMenuProps = {
    status: string;
    idSignatory: string;
}

type TypeData = {
    idSignatory: string;
    name_client: string;
    document: string;
    name_company: string;
    name_user: string;
    status: string;
    number_process: string;
    name_model: string;
    date: string;
    hours?: string;
    actions?: React.ReactNode
}

type FetchData = {
    token: string,
    qtdResults: number,
    filterKey?: string,
    value?: string,
    filterStatus: string;
    valueStatus?: string;
    search?: boolean;
    page?: number,
    startDate?: any,
    endDate?: any
}

type FilterActive = {
    status: boolean;
    select: boolean;
    date: boolean;
}

const columns: TypeColumn[] = [
    { label: 'Status', minWidth: 170, align: 'center' },
    { label: 'Processo', minWidth: 170, align: 'center' },
    { label: 'Documento Cliente', minWidth: 170, align: 'center' },
    { label: 'Nome Cliente', minWidth: 170, align: 'center' },
    { label: 'Nome Modelo', minWidth: 170, align: 'center' },
    { label: 'Data', minWidth: 170, align: 'center' },
    { label: 'Hora', minWidth: 170, align: 'center' },
    { label: 'Empresa', minWidth: 170, align: 'center' },
    { label: 'Usuário', minWidth: 170, align: 'center' },
    { label: 'Ações', minWidth: 170, align: 'center' },
];

const statusBackgroundColor: { [key: string]: string } = {
    "Pendente": "#FFFDD0",
    "Concluído": "#D1FAE5",
    "Cancelado": "#FEE2E2",
    "Frustrado": "#CFCFCF"
}

const statusColor: { [key: string]: string } = {
    "Pendente": "#D3A520",
    "Concluído": "#065F46",
    "Cancelado": "#991B1B",
    "Frustrado": "#808080"
}

const status = ['Pendente', 'Concluído', 'Cancelado', 'Frustrado']

const statusMapping: { [key: string]: string } = {
    "1": "Pendente",
    "2": "Concluído",
    "6": "Cancelado",
    "7": "Frustrado"
};

const ChoicesFilterResults = [
    { id: 'Documento', value: 'document' },
    { id: 'Nome do Cliente', value: 'name_client' },
    { id: 'Nome do Modelo', value: 'name_model' },
    { id: 'Número do Processo', value: 'number_process' },
    { id: 'Nome da Empresa', value: 'name_corporate' },
    { id: 'Usuário', value: 'email_user' }
]

const qtdResults = 20

const initialDate = new Date();
initialDate.setDate(initialDate.getDate() - 7);
const finalDate = new Date();

const formatDate = (date: Date) => {
    return new Intl.DateTimeFormat('pt-BR', {
        day: '2-digit',
        month: '2-digit',
        year: 'numeric'
    }).format(date);
};

const formatDateAndHours = (item: ItemContent) => {

    const dateTime = item.created_at.split(' às ');

    const date = dateTime[0];
    const time = dateTime[1];

    const [day, month, year] = date.split('/');
    const [hours, minutes, seconds] = time.split(':');

    let dateObject = new Date(parseInt(year), parseInt(month) - 1, parseInt(day), parseInt(hours), parseInt(minutes), parseInt(seconds));

    dateObject.setHours(dateObject.getHours() - 3);

    const formattedDate = dateObject.toLocaleDateString('pt-BR', {
        day: '2-digit',
        month: '2-digit',
        year: 'numeric',
    });

    const formattedTime = dateObject.toLocaleTimeString('pt-BR', {
        hour: '2-digit',
        minute: '2-digit'
    });

    return [formattedDate, formattedTime];
};

const fetchDataSignatory = async (data: FetchData) => {

    try {

        let url = `onboarding/signatory/list/?token=${data.token}&page=${data.page}&page_size=${qtdResults}`

        if (data.filterKey && data.value) url += `&${data.filterKey}=${data.value}`;

        if (data.filterStatus && data.valueStatus) url += `&${data.filterStatus}=${data.valueStatus}`;

        if (data.startDate && data.endDate) url += `&initial_date=${formatDate(data.startDate)}&final_date=${formatDate(data.endDate)}`

        const response = await APIOnboarding.get(url);

        return response.data

    } catch (error) {
        toast.error('Erro ao carregar onboardings');
        throw error;
    }
}

export default function Onboarding() {

    registerLocale('ptBR', ptBR as any);

    const navigate = useNavigate()
    const { setTitleBar, setPathTitleBar } = useCore()

    const { user } = useAuth()

    const { updateItems, setFileProcessing } = useItemsStore()

    const [openSendOboarding, setOpenSendOboarding] = useState<boolean>(false);
    const [tokenOnboarding, setTokenOnboarding] = useState<string>('')
    const [isStaff, setIsStaff] = useState<boolean>(false)

    const [countAlert, setCountAlert] = useState<number>(0)

    const [loadingPage, setLoadingPage] = useState<boolean>(true)

    const [filterActiveBorder, setFilterActiveBorder] = useState<FilterActive>({
        status: false,
        select: false,
        date: false,
    });

    const [filterValuesSelect, setFilterSelect] = useState<string>("0")
    const [orderDate, setOrderDate] = useState<boolean>(true)
    const [filterStatus, setFilterStatus] = useState<string>("0")
    const [filterValues, setFilterValues] = useState<string>("")

    const [originalRows, setOriginalRows] = useState<ResponseSignatoryRow[]>([])
    const [countOriginalRows, setCountOriginalRows] = useState<number>(0)

    const [haveModelSaved, setHaveModelSaved] = useState<boolean>(false)

    const [originalRowsSaved, setOriginalRowsSaved] = useState([])
    const [nameModel, setNameModel] = useState<string>('')
    const [titleForm, setTitleForm] = useState<string>('')

    const [startDate, setStartDate] = useState<Date>(initialDate);
    const [endDate, setEndDate] = useState<Date | undefined>(finalDate);

    const [isFilter, setIsFilter] = useState<boolean>(false);
    const [fecthData, setFecthData] = useState<boolean>(false);

    const [page, setPage] = useState<number>(1)
    const [resetPage, setResetPage] = useState(false);

    useLayoutEffect(() => {
        setTitleBar('Onboarding')
        setPathTitleBar('')
    }, [setPathTitleBar, setTitleBar])

    useEffect(() => {
        const companyId = user?.corporate_id!;
        const fetchData = async () => {
            try {
                const getToken = await api.get(`company/retrieve/${companyId}/`) as { [key: string]: any };

                const tokenOnboardingApi = getToken.content.token_onboarding;
                const using_onboarding = getToken.content.using_onboarding;

                if (tokenOnboardingApi && !using_onboarding) {
                    setTokenOnboarding(using_onboarding);
                    return;
                }

                setTokenOnboarding(tokenOnboardingApi)

            } catch (error) {
                toast.error("Ops.. Tivemos um problema, por favor tente novamente mais tarde!", {
                    position: toast.POSITION.TOP_RIGHT
                })
            }
        };

        fetchData();
    }, [user?.corporate_id]);

    const fetchData = async (data: FetchData) => {
        try {

            const resultsRows = await fetchDataSignatory(data);

            if (data.search && resultsRows.content.count === 0) {
                toast.dismiss()
                toast.error('Nenhum resultado encontrado', { autoClose: 2000 })
                setIsFilter(false)
                return
            }

            if (resultsRows.content.count === 0) {
                const responseOnboarding = await api.get('/onboarding/list/all/') as any;
                setHaveModelSaved(responseOnboarding.content.results.length !== 0 ? true : false)
                setLoadingPage(false);
                return;
            }

            setIsStaff(resultsRows.user.is_staff)
            setCountOriginalRows(resultsRows.content.count);

            if (resultsRows.user.file_processing){
                setCountAlert(state => state+1)
                if (countAlert < 1) {
                    setFileProcessing(true)
                    toast.info('Por favor, aguarde o termino do processamento em lote para disparar novos onboardings múltiplos', {autoClose: 6000})
                }
        }

            const rows = resultsRows.content.results
            .map((item: ItemContent) => {
                const name_company = item.data.find(d => d.label === "nome_empresa")?.value || "N/A";
                const name_user = item.layout.responsible_user || "N/A"
                const number_process = item.data.find(d => d.label === "numero_processo")?.value || "N/A";
                const document = item.data.find(d => d.label === "cpf_cnpj")?.value || "N/A";
                const name_client = item.data.find(d => d.label === "nome")?.value || "N/A";
                const name_model = item.layout.name_model || "N/A";
                const [date, hours] = formatDateAndHours(item)
                const status = statusMapping[item.status] || "N/A";

                return createData(item.id, status, number_process, document, name_client, name_model, date, hours, name_company, name_user);
            });

            setOriginalRows(rows);
            setLoadingPage(false);
        } catch (error) {
            toast.error("Ops.. Tivemos um problema, por favor tente novamente mais tarde!", {
                position: toast.POSITION.TOP_RIGHT
            });
            setLoadingPage(false);
        }
    };

    useEffect(() => {
        if (!tokenOnboarding) return;

        const fetchDataWrapper = async () => {

            const valueFilter = Object.entries(statusMapping).find(([_, val]) => val === filterStatus)?.[0]!;

            await fetchData({
                token: tokenOnboarding,
                qtdResults: qtdResults,
                filterKey: isFilter ? filterValuesSelect : '',
                value: isFilter ? filterValues : '',
                filterStatus: isFilter ? 'status' : '',
                valueStatus: isFilter ? valueFilter : '',
                page: resetPage ? 1 : page,
                startDate: isFilter && filterActiveBorder.date ? startDate : '',
                endDate: isFilter && filterActiveBorder.date ? endDate : '',
                search: isFilter ? true : false
            });
        };

        if (resetPage) {
            setPage(1);
            setResetPage(false);
        }

        fetchDataWrapper();
    }, [page, tokenOnboarding, fecthData]);


    useEffect(() => {
        const fetchData = async () => {
            try {
                const responseOnboarding = await api.get('/onboarding/list/all/') as { [key: string]: any };

                setCountOriginalRows(responseOnboarding.content.count)

                const rows = responseOnboarding.content.results.map((item: any) => {
                    return {
                        id_onboarding: item.id,
                        user: responseOnboarding.user.first_name,
                        name_model: item.name_model,
                        title_model: item.title_model,
                        model_onboarding: item.model_onboarding
                    };
                });

                setOriginalRowsSaved(rows)

            } catch (error) {
                toast.error('Erro ao buscar dados de onboarding e signatários');
            }
        };

        fetchData();
    }, [user?.corporate_id]);

    const orderDataChange = (e: MouseEvent<HTMLButtonElement>) => {
        setOrderDate(prevState => !prevState);

        const sortedRows = originalRows.slice().sort((a, b) => {
            const partsA = a.date.split('/');
            const dateA = new Date(parseInt(partsA[2], 10), parseInt(partsA[1], 10) - 1, parseInt(partsA[0], 10));

            const partsB = b.date.split('/');
            const dateB = new Date(parseInt(partsB[2], 10), parseInt(partsB[1], 10) - 1, parseInt(partsB[0], 10));

            return orderDate ? dateA.getTime() - dateB.getTime() : dateB.getTime() - dateA.getTime();
        });

        setOriginalRows(prevState => sortedRows);
    };

    const filterClientsChange = async (e: React.MouseEvent<HTMLButtonElement>) => {
        setIsFilter(true)
        setResetPage(true);
        setFecthData(state => !state)
    };

    const handleStatusFilterChange = (e: SelectChangeEvent<string>) => {
        setFilterStatus(e.target.value);

        if (e.target.value === "0") {
            setFilterActiveBorder((prev) => ({ ...prev, "status": false }));
        } else {
            setFilterActiveBorder((prev) => ({ ...prev, "status": true }));
        }

    };

    const handleFilterSelect = (e: SelectChangeEvent<string>) => {
        setFilterSelect(e.target.value);

        if (e.target.value === "0") {
            setFilterActiveBorder((prev) => ({ ...prev, "select": false }));
        } else {
            setFilterActiveBorder((prev) => ({ ...prev, "select": true }));
        }
    };

    const clearFiltersAll = async () => {
        setFilterSelect("0")
        setFilterStatus("0")
        setFilterValues("")
        setStartDate(initialDate)
        setEndDate(finalDate)
        setIsFilter(false)
        setFecthData(state => !state)
        setFilterActiveBorder({
            status: false,
            select: false,
            date: false,
        })
        setPage(1)

    }

    const handleSendOnboarding = async (e: SelectChangeEvent<string>) => {

        const selectedOptionId = e.target.value;

        if (selectedOptionId === '1') return navigate('create-onboarding')

        const selectedItem = originalRowsSaved.find((item: any) => item.id_onboarding === selectedOptionId)!;

        const { name_model, title_model, model_onboarding } = selectedItem;

        setNameModel(name_model)
        setTitleForm(title_model)
        updateItems(model_onboarding)

        setOpenSendOboarding(true)
    }

    const handleKeyDown = (ev: any) => {
        if (ev.key === 'Enter') {
            ev.preventDefault();
            setFilterSelect("0")
            filterClientsChange(ev.target.value);
            setPage(1)
        }
    }

    const handleChangePage = (e: React.ChangeEvent<unknown>, value: number) => {
        setPage(value);
    };

    const MoreOptionsMenu = ({ status: initialStatus, idSignatory }: MoreOptionsMenuProps) => {
        const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
        const [openModal, setOpenModal] = useState<boolean>(false);
        const [status, setStatus] = useState<string>(initialStatus);

        const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
            if (status !== 'Cancelado') {
                setAnchorEl(event.currentTarget);
            }
        };

        const handleClose = () => {
            setAnchorEl(null);
        };

        const handleOpenModal = () => {
            setOpenModal(true);
        };

        const handleCloseModal = () => {
            setOpenModal(false);
        };

        const editOnboarding = () => {
            navigate(`/onboarding/edit-contact/${idSignatory}`);
        };

        const downloadFile = () => {
            navigate(`/onboarding/view-response/${idSignatory}`);
        };

        const updateStatus = (id: string, newStatus: string) => {
            setOriginalRows(prevRows => prevRows.map(row =>
                row.idSignatory === id ? { ...row, status: newStatus } : row
            ));
            setStatus(newStatus);
        };

        const destroyOnboarding = async () => {
            const payload = JSON.stringify({
                status: '6'
            });

            const formData = new FormData();
            formData.append('content', payload);

            const response = await APIOnboarding.put(`onboarding/data/signatory/update_status/${idSignatory}/`, formData);

            if (response.status === 200) {
                handleCloseModal();
                updateStatus(idSignatory, 'Cancelado');
                toast.success('Onboarding cancelado com Sucesso!!!');
            } else {
                toast.error("Ops.. Tivemos um problema, por favor tente novamente mais tarde!", {
                    position: toast.POSITION.TOP_RIGHT
                });
            }
        };

        const menuItems = () => {
            if (status === 'Pendente') {
                return [
                    <MenuItem key="editar" onClick={() => { editOnboarding(); handleClose(); }}>
                        <Typography sx={{ cursor: 'pointer' }}>Editar</Typography>
                    </MenuItem>,
                    <MenuItem key="cancelar" onClick={() => { handleOpenModal(); handleClose(); }}>
                        <Typography sx={{ cursor: 'pointer', color: '#FFA890' }}>Cancelar</Typography>
                    </MenuItem>
                ];
            } else if (status === 'Concluído') {
                return [
                    <MenuItem key="visualizar" onClick={() => { downloadFile(); handleClose(); }}>
                        <Typography sx={{ cursor: 'pointer' }}>Visualizar</Typography>
                    </MenuItem>
                ];
            }
        };

        return (
            <Grid sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', gap: 0.7 }}>
                <IconButton onClick={handleClick} disabled={status === 'Cancelado'} sx={{ color: 'inherit' }}>
                    <MoreVertIcon />
                </IconButton>
                <Menu
                    anchorEl={anchorEl}
                    open={Boolean(anchorEl)}
                    onClose={handleClose}
                    disableScrollLock={true}
                >
                    {menuItems()}
                </Menu>

                {
                    openModal &&
                    <ModalDelete open={openModal} textBody="Deseja cancelar esse onboarding?" handleModalClose={handleCloseModal} handleDelete={destroyOnboarding} loading={false} />
                }
            </Grid>
        );
    };

    function createData(
        idSignatory: string,
        status: string,
        number_process: string,
        document: string,
        name_client: string,
        name_model: string,
        date: string,
        hours: string,
        name_company: string,
        name_user: string
    ): TypeData {
        const actions = <MoreOptionsMenu status={status} idSignatory={idSignatory} />;

        return { idSignatory, status, number_process, document, name_client, name_model, date, hours, name_company, name_user, actions };
    }

    function ComponentWelcome() {

        return (
            <Box
                display="flex"
                flexDirection="column"
                alignItems="center"
                justifyContent="center"
                height="100vh"
                textAlign="center"
                marginTop={-10}
            >
                <Typography variant="h4" gutterBottom>
                    Seja bem vindo ao Onboarding Checkview
                </Typography>
                <Typography variant="body1" gutterBottom>
                    Para iniciar, clique no botão abaixo para criar o seu primeiro onboarding
                </Typography>

                <Box
                    sx={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        width: 'auto',
                        gap: 5
                    }}
                >

                    <Button variant="contained" color="primary"
                        onClick={() => navigate('/onboarding/create-onboarding')}
                    >
                        Criar Onboarding
                    </Button>

                    {
                        haveModelSaved && (
                            <Button variant="contained" sx={{ color: '#FFFF', backgroundColor: '#9756A1' }}
                                onClick={() => navigate('/onboarding/saved')}
                            >
                                Formulários Salvos
                            </Button>
                        )
                    }

                </Box>
            </Box>
        );
    };

    return (

        <ProtectRoutesOnboarding>
            <ToastContainer />

            {loadingPage && <LoadingPage />}
            {
                originalRows.length === 0
                    ? <ComponentWelcome />
                    : (

                        <>
                            <SendOnboarding open={openSendOboarding} setOpen={setOpenSendOboarding} nameModelOnboarding={nameModel} titleOnboarding={titleForm}/>

                            <Grid container direction="column" sx={{ minHeight: '100vh', width: '100%' }}>

                                <Grid
                                    container
                                    gap={1}
                                    textAlign="center"
                                    sx={{ width: '100%' }}>

                                    <Grid
                                        item
                                        xs={12}
                                        sx={{
                                            display: 'flex',
                                            alignItems: 'center',
                                            margin: '10px 0 10px 0',
                                            height: '50px',
                                            width: '100%',
                                            gap: 1
                                        }}>

                                        <IconButton
                                            onClick={() => navigate('/')}
                                            sx={{
                                                height: '100%',
                                                backgroundColor: '#FFFFFF',
                                                border: '1px solid #D6D6D6',
                                                borderRadius: '12px',
                                                padding: '7px',
                                                width: '35px',
                                                '&:hover': {
                                                    backgroundColor: 'transparent'
                                                }
                                            }}
                                        >
                                            <KeyboardArrowLeftIcon
                                                fontSize='small'
                                                sx={{ color: 'black' }}
                                            />

                                        </IconButton>

                                        <Box sx={{
                                            display: 'flex',
                                            alignItems: 'center',
                                            flexBasis: 'auto',
                                            width: '100%',
                                            height: '50px',
                                            marginLeft: '15px',
                                            gap: 2
                                        }}
                                        >
                                            <Button sx={{
                                                height: '100%',
                                                width: 'auto',
                                                borderRadius: '8px',
                                                border: '1px solid #D6D6D6',
                                                fontSize: '14px',
                                                backgroundColor: '#FFFFFF',
                                                fontWeight: 'bold',
                                                textTransform: 'none',
                                                color: 'black',
                                                boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.1)',
                                                '&:hover': {
                                                    backgroundColor: '#FFFFFF',
                                                },
                                            }}
                                                onClick={() => navigate('/onboarding/saved')}
                                            >Formulários Salvos
                                            </Button>


                                            <Box sx={{ maxHeight: '50px', width: '190px', boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.1)', borderRadius: '8px', overflow: 'hidden' }}>
                                                <Select
                                                    MenuProps={{
                                                        PaperProps: {
                                                            style: {
                                                                borderColor: '#D1D5DB',
                                                                borderWidth: '1px',
                                                                borderStyle: 'solid',
                                                                maxWidth: '100%',
                                                                borderRadius: '8px',
                                                            }
                                                        },
                                                        disableScrollLock: true
                                                    }}
                                                    sx={{
                                                        maxHeight: '50px',
                                                        width: '100%',
                                                        fontSize: '14px',
                                                        backgroundColor: '#FFFFFF',
                                                        fontWeight: 'bold',
                                                        borderRadius: '8px',
                                                        border: filterActiveBorder.select ? '2px solid green' : '1px solid #D6D6D6',
                                                        '& .MuiOutlinedInput-notchedOutline': {
                                                            borderColor: '#D1D5DB',
                                                            borderRadius: '8px',
                                                        },
                                                        '&:hover .MuiOutlinedInput-notchedOutline': {
                                                            borderColor: '#D1D5DB',
                                                        },
                                                        '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                                                            borderColor: 'transparent',
                                                        }
                                                    }}
                                                    labelId="filtros-label"
                                                    id="filtros"
                                                    name="filtros"
                                                    value={filterValuesSelect}
                                                    onChange={handleFilterSelect}
                                                    required={true}
                                                >
                                                    <MenuItem key={`filtros-new`} value="0">
                                                        Filtros
                                                    </MenuItem>
                                                    {ChoicesFilterResults.map((choice) => (
                                                        (isStaff || choice.value !== 'name_corporate') && (
                                                            <MenuItem key={`filtros-${choice.id}`} value={choice.value}>
                                                                {choice.id}
                                                            </MenuItem>
                                                        )
                                                    ))}
                                                </Select>
                                            </Box>

                                            <Box sx={{ boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.1)', borderRadius: '8px' }}>
                                                <DatePicker
                                                    selectsRange={true}
                                                    onChange={(dates: any) => {
                                                        const [start, end] = dates;

                                                        if (start) {
                                                            setEndDate(undefined)
                                                        }

                                                        setStartDate(start);
                                                        setEndDate(end);

                                                        if (end) {
                                                            setFilterActiveBorder((prev) => ({ ...prev, "date": true }));
                                                        }
                                                    }}
                                                    startDate={startDate}
                                                    endDate={endDate}
                                                    dateFormat="dd/MM/yyyy"
                                                    locale="ptBR"
                                                    className={`custom-datepicker ${filterActiveBorder.date ? 'active' : ''}`}
                                                    //onKeyDown={(e) => e.preventDefault()}
                                                />
                                            </Box>

                                            <Button
                                                endIcon={<FilterAltOffIcon />}
                                                sx={{
                                                    maxWidth: '100%',
                                                    height: '100%',
                                                    backgroundColor: '#FFA890',
                                                    color: '#FFFFFF',
                                                    fontWeight: 'bold',
                                                    border: 'none',
                                                    textTransform: 'none',
                                                    '&:hover': {
                                                        backgroundColor: '#FFA890',
                                                        color: 'white',
                                                        border: 'none'
                                                    },
                                                }}
                                                variant="outlined"
                                                onClick={clearFiltersAll}>
                                                Limpar Filtros
                                            </Button>

                                        </Box>

                                        <Grid item xs={6} sx={{ maxHeight: '500px', display: 'flex', justifyContent: 'flex-end' }}>
                                            <FormControl sx={{ width: 'auto', maxHeight: '500px', marginLeft: 0.5 }}>
                                                <Select
                                                    MenuProps={{
                                                        PaperProps: {
                                                            style: {
                                                                borderColor: '#D1D5DB',
                                                                borderWidth: '1px',
                                                                borderStyle: 'solid',
                                                                maxWidth: '100%',
                                                                maxHeight: 200,
                                                                overflowY: 'auto',
                                                            }
                                                        },
                                                        disableScrollLock: true
                                                    }}
                                                    sx={{
                                                        width: '100%',
                                                        height: '50px',
                                                        borderRadius: '8px',
                                                        backgroundColor: '#FFA890',
                                                        color: '#FFFFFF',
                                                        fontWeight: 'bold',
                                                        padding: '3px',
                                                        '& .MuiOutlinedInput-notchedOutline': {
                                                            borderColor: 'transparent',
                                                        },
                                                        '&:hover .MuiOutlinedInput-notchedOutline': {
                                                            borderColor: 'transparent',
                                                        },
                                                        '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                                                            borderColor: 'transparent',
                                                        },
                                                        '& .MuiSelect-icon': {
                                                            color: '#FFFFFF',
                                                        },
                                                        "& .Mui-disabled": {
                                                            WebkitTextFillColor: "#FFFFFF",
                                                            cursor: 'pointer',
                                                        }
                                                    }}
                                                    labelId="onboardings-label"
                                                    id="onboardings"
                                                    name="onboardings"
                                                    value=''
                                                    required={true}
                                                    onChange={handleSendOnboarding}
                                                    displayEmpty
                                                    renderValue={(selected) => {
                                                        if (selected.length === 0) {
                                                            return <Typography sx={{ fontWeight: 'bold' }}>Criar Novo Onboarding</Typography>
                                                        }
                                                        return selected;
                                                    }}
                                                >
                                                    <MenuItem key={'new-form'} value='1'>Novo Onboarding</MenuItem>

                                                    {originalRowsSaved.length > 0 && originalRowsSaved.map((options: any) => (
                                                        <MenuItem key={options.id_onboarding} value={options.id_onboarding}>
                                                            {options.name_model.length >= 20 ? `${options.name_model.slice(0,20)}...` : options.name_model}
                                                            <ListItemIcon sx={{ color: '#FFA890', minWidth: 'auto', marginLeft: 'auto' }}>
                                                                <ArrowRightAltIcon />
                                                            </ListItemIcon>
                                                        </MenuItem>
                                                    ))}

                                                </Select>
                                            </FormControl>

                                        </Grid>

                                    </Grid>

                                    <Grid
                                        item
                                        xs={12}
                                        sx={{
                                            display: 'flex',
                                            alignItems: 'center',
                                            justifyContent: 'space-between',
                                            margin: '10px 0 10px 0',
                                            height: '50px',
                                            gap: 1
                                        }}>

                                        <Select
                                            sx={{
                                                width: '25%',
                                                height: '100%',
                                                fontSize: '14px',
                                                fontWeight: 'bold',
                                                backgroundColor: '#FFFFFF',
                                                boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.1)',
                                                border: filterActiveBorder?.status ? '2px solid green' : '1px solid #D6D6D6',
                                                '& .MuiOutlinedInput-notchedOutline': {
                                                    borderColor: 'transparent',
                                                },
                                                '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                                                    borderColor: 'transparent',
                                                },
                                                '&:hover .MuiOutlinedInput-notchedOutline': {
                                                    borderColor: 'transparent',
                                                },
                                            }}
                                            MenuProps={{ disableScrollLock: true }}
                                            required={true}
                                            labelId="status-label"
                                            id="status"
                                            name="status"
                                            value={filterStatus}
                                            onChange={handleStatusFilterChange}
                                        >
                                            <MenuItem key={`status-none`} value="0">
                                                Selecione um Status
                                            </MenuItem>
                                            {status?.map((value, index) => (
                                                <MenuItem key={`status-${value}`} value={value}>
                                                    {value}
                                                </MenuItem>
                                            ))}
                                        </Select>

                                        <FormControl sx={{ width: '60%', height: '100%' }} variant="standard">
                                            <Input
                                                id="input-search"
                                                placeholder="Buscar"
                                                //onKeyDown={handleKeyDown}
                                                value={filterValues}
                                                onChange={(event) => setFilterValues(event.target.value)}
                                                startAdornment={
                                                    <InputAdornment position="start">
                                                        <SearchIcon sx={{ color: '#D1D5DB', fontSize: '20px', marginLeft: '10px' }} />
                                                    </InputAdornment>
                                                }
                                                sx={{
                                                    '&:before': { borderBottom: 'none' },
                                                    '&:after': { borderBottom: 'none' },
                                                    '&:hover:not(.Mui-disabled):before': { borderBottom: 'none' },
                                                    padding: '10px 0',
                                                    border: '1px solid #E0E0E0',
                                                    borderRadius: '4px',
                                                    backgroundColor: '#FFFFFF',
                                                    height: '100%',
                                                    boxShadow: '0 0 3px rgba(0, 0, 0, 0.1)',
                                                    color: '#9CA3AF'
                                                }}
                                            />
                                        </FormControl>

                                        <Button
                                            sx={{
                                                maxWidth: '100%',
                                                height: '100%',
                                                backgroundColor: '#FFA890',
                                                color: '#FFFFFF',
                                                borderRadius: '8px',
                                                fontWeight: 'bold',
                                                border: 'none',
                                                '&:hover': {
                                                    backgroundColor: '#FFA890',
                                                    color: 'white',
                                                    border: 'none'
                                                },
                                            }}
                                            variant="outlined"
                                            onClick={filterClientsChange}>
                                            Pesquisar
                                        </Button>
                                    </Grid>

                                </Grid>


                                <Paper sx={{ width: '100%', overflow: 'hidden', marginTop: '30px', borderRadius: '15px', boxShadow: '0px 0px 3px 1px rgba(0, 0, 0, 0.2)' }}>
                                    <TableContainer>
                                        <Table sx={{ minWidth: 650 }} aria-label="simple table">
                                            <TableHead>
                                                <TableRow sx={{}}>
                                                    {columns.map((item, index) => (
                                                        <TableCell
                                                            key={index}
                                                            align={item.align}
                                                            sx={{
                                                                color: '#6B7280',
                                                                backgroundColor: '#F9FAFB',
                                                                fontWeight: '600',
                                                                borderBottom: '1px solid rgba(224, 224, 224, 1)',
                                                                padding: '10px',
                                                            }}
                                                        >
                                                            {item.label === 'Data' ? (
                                                                <Typography onClick={orderDataChange} sx={{ cursor: 'pointer', display: 'flex', justifyContent: 'center', gap: 0.2, fontWeight: '600' }}>
                                                                    {item.label} {orderDate ? <ArrowDownwardIcon fontSize='small' /> : <ArrowUpwardIcon fontSize='small' />}
                                                                </Typography>
                                                            ) : (
                                                                item.label
                                                            )}
                                                        </TableCell>
                                                    ))}
                                                </TableRow>
                                            </TableHead>
                                            <TableBody>
                                                {originalRows.map((row: any, rowIndex) => (
                                                    <TableRow
                                                        key={rowIndex}
                                                        sx={{
                                                            backgroundColor: '#FFFFFF',
                                                            '&:last-child td, &:last-child th': { border: 0 },
                                                        }}
                                                    >
                                                        <TableCell align="center" sx={{ padding: '10px', borderBottom: '1px solid rgba(224, 224, 224, 1)' }}>
                                                            <Grid
                                                                sx={{
                                                                    display: 'inline-block',
                                                                    width: '90px',
                                                                    backgroundColor: statusBackgroundColor[row.status],
                                                                    color: statusColor[row.status],
                                                                    fontWeight: 'bold',
                                                                    borderRadius: '10px'
                                                                }}
                                                            >
                                                                {row.status}
                                                            </Grid>
                                                        </TableCell>
                                                        <TableCell align="center" sx={{ padding: '10px', borderBottom: '1px solid rgba(224, 224, 224, 1)' }}>{row.number_process}</TableCell>
                                                        <TableCell align="center" sx={{ padding: '10px', borderBottom: '1px solid rgba(224, 224, 224, 1)' }}>{row.document}</TableCell>
                                                        <TableCell align="center" sx={{ padding: '10px', borderBottom: '1px solid rgba(224, 224, 224, 1)' }}>{row.name_client}</TableCell>
                                                        <TableCell align="center" sx={{ padding: '10px', borderBottom: '1px solid rgba(224, 224, 224, 1)' }}>
                                                            <Tooltip title={row.name_model} placement="right">
                                                                <span>
                                                                    {row.name_model.length >= 15 ? `${row.name_model.slice(0, 15)}...` : row.name_model}
                                                                </span>
                                                            </Tooltip>
                                                        </TableCell>
                                                        <TableCell align="center" sx={{ padding: '10px', borderBottom: '1px solid rgba(224, 224, 224, 1)' }}>{row.date}</TableCell>
                                                        <TableCell align="center" sx={{ padding: '10px', borderBottom: '1px solid rgba(224, 224, 224, 1)' }}>{row.hours}</TableCell>
                                                        <TableCell align="center" sx={{ padding: '10px', borderBottom: '1px solid rgba(224, 224, 224, 1)' }}>
                                                            <Tooltip title={row.name_company} placement="right">
                                                                <span>
                                                                    {row.name_company.length >= 15 ? `${row.name_company.slice(0, 15)}...` : row.name_company}
                                                                </span>
                                                            </Tooltip>
                                                        </TableCell>
                                                        <TableCell align="center" sx={{ padding: '10px', borderBottom: '1px solid rgba(224, 224, 224, 1)' }}>
                                                            <Tooltip title={row.name_user} placement="right">
                                                                <span>
                                                                    {row.name_user.length >= 15 ? `${row.name_user.slice(0, 15)}...` : row.name_user}
                                                                </span>
                                                            </Tooltip>
                                                        </TableCell>
                                                        <TableCell align="center" sx={{ padding: '10px', borderBottom: '1px solid rgba(224, 224, 224, 1)' }}>{row.actions}</TableCell>
                                                    </TableRow>
                                                ))}
                                            </TableBody>
                                        </Table>
                                    </TableContainer>
                                </Paper>


                                <Grid item sx={{ marginTop: 'auto' }}>
                                    <Paginator count={countOriginalRows} pageNumber={page} qtdResults={qtdResults} onChange={handleChangePage} />
                                </Grid>
                            </Grid>
                        </>
                    )
            }

        </ProtectRoutesOnboarding>
    )
}