import axios from "axios"

const baseUrl = process.env.REACT_APP_API_URL_MATCH

export async function checkMatchGet(path: string) {
	return new Promise(async (resolve) => {
		await axios.get(`${baseUrl}/${path}`, defaults).then((response) => {
			resolve(response)
		}).catch(async (error) => {
			resolve(error.response)
		})
	})
}

export async function checkMatchPost(path: string, data: object) {
	return new Promise(async (resolve) => {
		await axios.post(`${baseUrl}/${path}`, data , defaults).then((response) => {
			resolve(response)
		}).catch(async (error) => {
			resolve(error.response)
		})
	})
}

export const defaults = {
    headers: {
        Authorization: '',
        "Content-Type": "application/json"
    }
}