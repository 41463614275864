import { create } from 'zustand';
import { v4 as uuidv4 } from "uuid";

export type DraggableItem = {
    id?: string;
    key?: string;
    id_choice?: string;
    type?: string;
    label?: string;
    required?: boolean;
    name?: string;
    error?: string;
    options?: string[];
    validations?: string[];
    validations_input?: string[];
    reference_id?: string;
    length_label?: number;
    length_value?: number;
    price?: number;
    onDragEnd?: (item: DraggableItem) => void;
}

type ValidationItem = string | { id: string; name: string; price: string };

type FormState = {
    fileProcessing: boolean;
    items: DraggableItem[];
    basePrice: number;
    totalPrice: number;
    label: Record<string, string>;
    optionsValues: Record<string, string[]>;
    valueRequired: Record<string, boolean>;
    validationsOptions: Record<string, ValidationItem[]>;
    validationsInput: { id: string; name: string }[];
    addItems: (item: DraggableItem) => void;
    calculatePrice: () => void;
    removeItems: (key: string) => void;
    updateItems: (items: DraggableItem[]) => void;
    setLabel: (key: string, value: string) => void;
    setOptionsValues: (key: string, options: string[]) => void;
    setValueRequired: (key: string, value: boolean) => void;
    clearFormValues: () => void;
    setFileProcessing: (newState: boolean) => void;
    toggleValidation: (key: string, validation: { id: string; name: string; price: string }) => void;
    toggleValidationInput: (validation: { id: string; name: string; price: string }) => void;
    updateValidationsInput: (newValidations: { id: string; name: string }[]) => void;
}

export const useItemsStore = create<FormState>((set, get) => ({
    fileProcessing: false,
    items: [],
    basePrice: 2.8,
    totalPrice: 2.8,
    label: {},
    optionsValues: {},
    valueRequired: {},
    validationsOptions: {},
    validationsInput: [],

    addItems: (item) => {
        set((state) => ({
            items: [
                ...state.items,
                {
                    id: item.id,
                    key: uuidv4(),
                    reference_id: item.reference_id,
                    id_choice: item.id_choice,
                    type: item.type,
                    label: item.label,
                    required: item.required || false,
                    name: item.name,
                    error: item.error,
                    options: item.options || [],
                    validations: item.validations,
                    length_label: item.length_label,
                    length_value: item.length_value,
                    price: item.price,
                },
            ],
        }));
        get().calculatePrice();
    },

    updateItems: (updatedItems) => set(() => ({
        items: updatedItems,
    })),

    removeItems: (key) => {
        set((state) => ({
            items: state.items.filter((item) => item.key !== key),
        }));
        get().calculatePrice();
    },

    setLabel: (key, value) => set((state) => ({
        label: { ...state.label, [key]: value },
    })),

    calculatePrice: () => {
        const { items, basePrice } = get();

        const choicesTextFields = ['0', '3', '4', '5', '6']
        const ChoicesAttachmentsFields = ['1', '2']

        const textFields = items.filter(
            (item) => choicesTextFields.includes(item.id_choice!)
        );

        const attachments = items.filter(
            (item) => ChoicesAttachmentsFields.includes(item.id_choice!)
        );

        const textFieldsExcess = textFields.length > 15 ? textFields.slice(15) : [];
        const attachmentsExcess = attachments.filter(
            (item) => item.type !== "19"
        ).length > 5
            ? attachments.slice(6)
            : [];
        const additionalTextPrice = textFieldsExcess.reduce((acc, item) => acc + item.price!, 0);
        const additionalAttachmentsPrice = attachmentsExcess.reduce((acc, item) => acc + item.price!, 0);

        const signaturePrice = attachments
            .filter((item) => item.type === "19")
            .reduce((acc, item) => acc + item.price!, 0);

        const newTotalPrice =
            basePrice + additionalTextPrice + additionalAttachmentsPrice + signaturePrice;

        set({ totalPrice: newTotalPrice });
    },

    setOptionsValues: (key, options) => set((state) => ({
        optionsValues: { ...state.optionsValues, [key]: options },
    })),

    setValueRequired: (key, value) => set((state) => ({
        valueRequired: { ...state.valueRequired, [key]: value },
    })),

    toggleValidation: (key, validation) => set((state) => {

        const isValidationObject = (item: ValidationItem): item is { id: string; name: string; price: string } => {
            return typeof item === 'object' && item !== null && 'id' in item;
        };

        const currentValidations = state.validationsOptions[key] || [];

        const exists = currentValidations.some((v) => isValidationObject(v) && v.id === validation.id);

        const priceChange = exists
        ? -parseFloat(validation.price) 
        : parseFloat(validation.price);

        return {
            validationsOptions: {
                ...state.validationsOptions,
                [key]: exists
                    ? currentValidations.filter((v) => !(isValidationObject(v) && v.id === validation.id))
                    : [...currentValidations, validation],
            },
            totalPrice: state.totalPrice + priceChange,
        };
    }),

    toggleValidationInput: (validation) => set((state) => {
        const exists = state.validationsInput.some((item) => item.id === validation.id);
    
        const updatedValidations = exists
            ? state.validationsInput.filter((item) => item.id !== validation.id)
            : [...state.validationsInput, validation];
    
        const priceChange = exists
            ? -parseFloat(validation.price) 
            : parseFloat(validation.price); 
  
        return {
            validationsInput: updatedValidations,
            totalPrice: state.totalPrice + priceChange, 
        };
    }),
    
    updateValidationsInput: (newValidations) => set(() => ({
        validationsInput: newValidations,
    })),

    clearFormValues: () => set(() => ({
        items: [],
        label: {},
        optionsValues: {},
        valueRequired: {},
        validationsOptions: {},
        validationsInput: [],
        totalPrice: 2.80
    })),

    setFileProcessing: (newState) => set(() => ({
        fileProcessing: newState,
    })),
}));
