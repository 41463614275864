import * as yup from 'yup'
import { useFormik } from 'formik'
import Grid from '@mui/material/Grid'
import { Profiles } from './profiles'
import { toast } from 'react-toastify'
import Button from "@mui/material/Button"
import * as api from '../../../services/api'
import TextField from '@mui/material/TextField'
import { ToastContainer } from "react-toastify"
import { useCore } from '../../../hooks/useCore'
import { useAuth } from '../../../hooks/useAuth'
import { LooseObject } from '../../../types/core'
import { ProductProps } from '../../../types/product'
import BusinessIcon from '@mui/icons-material/Business'
import { RetrieveUserProps } from '../../../types/user'
import { useParams, useLocation } from "react-router-dom"
import FactCheckIcon from '@mui/icons-material/FactCheck'
import { LoadingPage } from '../../../components/LoadingPage'
import DirectionsCarIcon from '@mui/icons-material/DirectionsCar'
import AssignmentIndIcon from '@mui/icons-material/AssignmentInd'
import { Chip, Divider, FormControl, MenuItem, Select, FormControlLabel, Switch } from '@mui/material'
import { useCallback, useEffect, useLayoutEffect, useState } from 'react'
import { dysplayCategory } from '../../Product/CreateOrUpdateProduct/category'

const validationSchema = yup.object({
  email: yup
    .string()
    .email('Informe um email válido')
    .required('O campo Email do responsável deve ser preenchido'),
})

interface Values {
  first_name: string
  last_name: string
  birth: string
  email: string
  profile: string
  company: string | null
}

const initialValues: Values = {
  first_name: '',
  last_name: '',
  birth: '',
  email: '',
  profile: '1',
  company: null,
}

export default function CreateOrUpdateUser(): JSX.Element {
  const location: LooseObject = useLocation()
  let { id, company_id } = useParams()
  const { user } = useAuth()
  const { setTitleBar, setPathTitleBar } = useCore()

  const { handleChange, handleSubmit, values, errors, setFieldValue, touched } = useFormik({
    initialValues: initialValues,
    validationSchema: validationSchema,
    onSubmit: createOrUpdateUser,
  })

  const [loading, setLoading] = useState<boolean>(false)
  const [listProducts, setListProducts] = useState<ProductProps[]>([])
  const [listPermissions, setListPermissions] = useState<ProductProps[]>([])
  const [permissionsProducts, setPermissionsProducts] = useState<string[]>([])
  const [permissionsSystem, setPermissionsSystem] = useState<string[]>([])

  const addProductId = (product_id: string) => {
    if (permissionsProducts.indexOf(product_id) > -1) {
      setPermissionsProducts(permissionsProducts.filter(item => item !== product_id))
    } else {
      setPermissionsProducts([...permissionsProducts, product_id])
    }
  }

  const addPermissionId = (permissionId: string) => {
    if (permissionsSystem.indexOf(permissionId) > -1) {
      setPermissionsSystem(permissionsSystem.filter(item => item !== permissionId))
    } else {
      setPermissionsSystem([...permissionsSystem, permissionId])
    }
  }

  const getUser = useCallback(async () => {
    setLoading(true)
    const res = await api.get(`user/retrieve/${id}/`) as RetrieveUserProps
    const response = res as RetrieveUserProps
    setFieldValue('first_name', response.content.first_name)
    setFieldValue('last_name', response.content.last_name)
    setFieldValue('birth', response.content.birth)
    setFieldValue('email', response.content.email)
    setFieldValue('company', response.content.company)
    setFieldValue('profile', response.content.profile)
    setPermissionsProducts(response.content.permissions_products)
    setPermissionsSystem(response.content.permissions_system)
    setLoading(false)

    async function getProducts() {
      await api.get(`user/list_products/?user_id=${id}`).then((response: any) => {
        if (typeof response.content === "string") {
          toast.error(response.content, {
            position: toast.POSITION.TOP_RIGHT
          })
        } else {
          setListProducts(response.content)
        }
      }).catch((error: any) => {
        toast.error(error.data.detail, {
          position: toast.POSITION.TOP_RIGHT
        })
      })
    }

    async function getPermissions() {
      await api.get(`user/list_permissions/?user_id=${id}`).then((response: any) => {
        if (typeof response.content === "string") {
          toast.error(response.content, {
            position: toast.POSITION.TOP_RIGHT
          })
        } else {
          setListPermissions(response.content)
        }
      }).catch((error: any) => {
        toast.error(error.data.detail, {
          position: toast.POSITION.TOP_RIGHT
        })
      })
    }

    await getProducts()
    await getPermissions()

  }, [setFieldValue, id])

  async function createOrUpdateUser() {
    if (id) {
      toast.loading("Atualizando...", {
        position: toast.POSITION.TOP_RIGHT,
      })

      const data = {
        first_name: values.first_name,
        last_name: values.last_name,
        birth: values.birth,
        email: values.email,
        profile: values.profile,
        company: values.company,
        permissions_products: permissionsProducts,
        permissions_system: permissionsSystem
      }

      var resUpdated = await api.put(`user/update/${id}/`, data) as { [key: string]: any }

      toast.dismiss()

      if (resUpdated.status === 200) {
        toast.success("Dados atualizados com sucesso!", {
          position: toast.POSITION.TOP_RIGHT
        })
      } else {
        toast.error("Ops.. Tivemos um problema, por favor tente novamente mais tarde!", {
          position: toast.POSITION.TOP_RIGHT
        })
      }
    } else {
      toast.loading("Criando...", {
        position: toast.POSITION.TOP_RIGHT,
      })

      values.company = company_id as string | null

      var resCreated = await api.post('user/create/', values) as { [key: string]: any }

      toast.dismiss()

      if (resCreated.status === 201) {
        toast.success("Usuário cadastrado com sucesso!", {
          position: toast.POSITION.TOP_RIGHT
        })
      } else {
        toast.error("Ops.. Tivemos um problema, por favor tente novamente mais tarde!", {
          position: toast.POSITION.TOP_RIGHT
        })
      }
    }
  }

  useEffect(() => {
    if (id) getUser().catch(console.error)
  }, [getUser, id])

  useEffect(() => {
    if (id) {
      if (values.email.length > 0) {
        setTitleBar(`Usuário - ${values.email}`)
      }
    } else if (company_id) {
      setTitleBar(`Novo usuário para a empresa ${location.state?.fantasy_name}`)
    } else {
      setTitleBar('Novo usuário administrador')
    }
  }, [company_id, id, location.state?.fantasy_name, setTitleBar, values.email])

  useLayoutEffect(() => {
    setPathTitleBar(undefined)
  }, [setPathTitleBar])

  return (
    <>
      <ToastContainer />
      {
        !loading
          ? <form onSubmit={handleSubmit}>
            <Grid
              container
              spacing={2}
              alignItems="center"
              justifyContent="center">
              <Grid item md={6} xs={12}>
                Nome
                <TextField
                  fullWidth
                  required={true}
                  id="first_name"
                  name="first_name"
                  placeholder="Informe o nome"
                  value={values.first_name}
                  onChange={handleChange}
                  error={touched.first_name && Boolean(errors.first_name)}
                  helperText={touched.first_name && errors.first_name}
                />
              </Grid>
              <Grid item md={6} xs={12}>
                Sobrenome
                <TextField
                  fullWidth
                  required={true}
                  id="last_name"
                  name="last_name"
                  placeholder="Informe o sobrenome"
                  value={values.last_name}
                  onChange={handleChange}
                  error={touched.last_name && Boolean(errors.last_name)}
                  helperText={touched.last_name && errors.last_name}
                />
              </Grid>
              <Grid item md={3} xs={12}>
                Nascimento
                <TextField
                  fullWidth
                  type="date"
                  InputLabelProps={{ shrink: true }}
                  id="birth"
                  name="birth"
                  value={values.birth}
                  onChange={handleChange}
                  error={touched.birth && Boolean(errors.birth)}
                  helperText={touched.birth && errors.birth}
                />
              </Grid>
              <Grid item md={9} xs={12}>
                Email
                <TextField
                  fullWidth
                  required={true}
                  id="email"
                  name="email"
                  placeholder="Informe um email"
                  value={values.email}
                  onChange={handleChange}
                  error={touched.email && Boolean(errors.email)}
                  helperText={touched.email && errors.email}
                />
              </Grid>
              {
                user?.profile === "3"
                  ? <Grid item md={12} xs={12}>
                    Perfil
                    <FormControl fullWidth>
                      <Select
                        fullWidth
                        labelId="profile-label"
                        id="id_profile"
                        name="profile"
                        value={values.profile}
                        onChange={handleChange}
                      >
                        {Profiles?.map((item) => (
                          <MenuItem key={`profile-${item.id}`} value={item.id}>
                            {item.name}
                          </MenuItem>
                        ))}
                      </Select>
                      <div style={{ fontSize: '.8rem', color: '#777777' }}>
                        <ol>
                          <li>
                            <strong>Usuário comum: </strong>Usuário com acesso básico ao sistema
                          </li>
                          <li>
                            <strong>Representante da empresa: </strong>Usuário com acesso básico ao sistema, acesso aos usuários relacionados a mesma empresa que ele e acesso à informações monetárias na dashboard
                          </li>
                          <li>
                            <strong>Administrador do sistema: </strong>Usuário com <strong>TODOS</strong> os acessos do sistema
                          </li>
                        </ol>
                      </div>
                    </FormControl>
                  </Grid>
                  : <></>
              }
              {
                user?.profile !== "1" && id
                  ? <Grid
                    item md={12} xs={12}
                  >
                    <h3><strong>Produtos</strong></h3>
                    <p><strong>{dysplayCategory["1"]}</strong></p>
                    <Grid
                      container
                      gap={2}
                    >
                      {
                        listProducts?.filter(item => item.category === "1").length > 0 ?
                          listProducts?.filter(item => item.category === "1").map((item) =>
                            <Chip
                              icon={<DirectionsCarIcon />}
                              label={item.name}
                              onClick={() => addProductId(item.id)}
                              variant="filled"
                              color={permissionsProducts.indexOf(item.id) > -1 ? "warning" : "default"}
                            />
                          )
                          : "Nenhum produto liberado nessa categoria"
                      }
                    </Grid>
                    <br />
                    <Divider />
                    <p><strong>{dysplayCategory["2"]}</strong></p>
                    <Grid
                      container
                      gap={2}
                    >
                      {
                        listProducts?.filter(item => item.category === "2").length > 0 ?
                          listProducts?.filter(item => item.category === "2").map((item) =>
                            <Chip
                              icon={<AssignmentIndIcon />}
                              label={item.name}
                              onClick={() => addProductId(item.id)}
                              variant="filled"
                              color={permissionsProducts.indexOf(item.id) > -1 ? "info" : "default"}
                            />
                          )
                          : "Nenhum produto liberado nessa categoria"
                      }
                    </Grid>
                    <br />
                    <Divider />
                    <p><strong>{dysplayCategory["3"]}</strong></p>
                    <Grid
                      container
                      gap={2}
                    >
                      {
                        listProducts?.filter(item => item.category === "3").length > 0 ?
                          listProducts?.filter(item => item.category === "3").map((item) =>
                            <Chip
                              icon={<FactCheckIcon />}
                              label={item.name}
                              onClick={() => addProductId(item.id)}
                              variant="filled"
                              color={permissionsProducts.indexOf(item.id) > -1 ? "success" : "default"}
                            />
                          )
                          : "Nenhum produto liberado nessa categoria"
                      }
                    </Grid>
                    <br />
                    <Divider />
                    <p><strong>{dysplayCategory["4"]}</strong></p>
                    <Grid
                      container
                      gap={2}
                    >
                      {
                        listProducts?.filter(item => item.category === "4").length > 0 ?
                          listProducts?.filter(item => item.category === "4").map((item) =>
                            <Chip
                              icon={<BusinessIcon />}
                              label={item.name}
                              onClick={() => addProductId(item.id)}
                              variant="filled"
                              color={permissionsProducts.indexOf(item.id) > -1 ? "error" : "default"}
                            />
                          )
                          : "Nenhum produto liberado nessa categoria"
                      }
                    </Grid>
                  </Grid>
                  : <></>
              }

              {
                user?.profile !== "1" && id && (
                  <Grid item md={12} xs={12}>
                    <h3><strong>Permissões</strong></h3>
                    <Grid container gap={2}>
                      {
                        listPermissions.map((permission: any) => (
                          <FormControlLabel
                            key={permission.id} 
                            control={
                              <Switch
                                checked={permissionsSystem.includes(permission.id)}
                                onChange={() => addPermissionId(permission.id)}
                                color="primary"
                              />
                            }
                            label={permission.name}
                          />
                        ))
                      }
                    </Grid>
                  </Grid>
                )
              }


              <Grid
                item
                alignItems="center"
                alignContent="center">
                <Button variant="contained" size="large" color="success" type="submit">
                  {id ? 'Atualizar' : 'Cadastrar'}
                </Button>
              </Grid>
            </Grid>
          </form>
          : <LoadingPage />
      }
    </>
  )
}