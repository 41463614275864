import React from "react";
import { useItemsStore } from "../../../contexts/zustand/onboarding"

import {
    Modal,
    Box,
    Typography,
    Button
} from '@mui/material';

const modalStyle = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '90%',
    maxWidth: '800px',
    bgcolor: '#FFFFFF',
    boxShadow: 24,
    p: 4,
    borderRadius: '8px',
    maxHeight: '90vh',
    overflowY: 'auto'
};


export default function ViewValidations({ openViewValidations, setOpenViewValidations, setOpenSendOnboarding }: any) {

    const { items, validationsInput, validationsOptions } = useItemsStore()

    const handleClose = () => {
        setOpenViewValidations(false);
    }

    const handleAgree = () => {
        setOpenViewValidations(false); 
        setOpenSendOnboarding(true);
    };

    return (
        <Modal
            open={openViewValidations}
            onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <Box sx={modalStyle}>

                <Box
                    sx={{
                        textAlign: 'center'
                    }}>
                    <Typography sx={{ fontSize: '26px', color: 'red' }}>Atenção</Typography>

                    <Typography sx={{ fontSize: '20px' }}>Os seguintes campos possuem <strong>validações ativas:</strong></Typography>
                </Box>

                <Box sx={{ marginTop: '15px' }}>
                    <Typography><strong>Validações de arquivos</strong></Typography>
                    {items.map((item, index) => (
                        <Box key={index} sx={{ marginBottom: '10px' }}>
                            <Box sx={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
                                <Typography>
                                    Campo: {item.label!.length >= 25 ? `${item.label?.slice(0, 25)}...` : item.label}
                                </Typography>
                                {item.validations &&
                                    item.validations!.map((ok, idx) => {
                                        const matchingValidation = validationsOptions[item.key!]?.find(
                                            (v) => typeof v === 'object' && v.id === ok
                                        );

                                        return (
                                            <Typography key={idx} sx={{ backgroundColor: '#D1FAE5', padding: '2px 6px', borderRadius: '4px' }}>
                                                {matchingValidation ? (matchingValidation as any).name : 'Não encontrado'}
                                            </Typography>
                                        );
                                    })}
                            </Box>
                        </Box>
                    ))}

                </Box>
                
                {validationsInput.length !==0 &&
                    <Box sx={{ marginTop: '15px' }}>
                        <Typography><strong>Validações de CPF/CNPJ de entrada</strong></Typography>
                        {
                            <>
                                <Typography>{validationsInput.map((item, index) => (item.name)).join(' /// ')}</Typography>
                            </>
                        }
                    </Box>
                }

                <Box sx={{ display: 'flex', justifyContent: 'space-around', alignItems: 'center', marginTop: 4 }} gap={2}>

                    <Button
                        sx={{
                            height: "45px",
                            backgroundColor: '#D12E2E',
                            color: 'white',
                            borderRadius: '8px',
                            padding: '10px 32px',
                            textTransform: 'none'
                        }}
                        size="large"
                        variant="contained"
                        onClick={handleClose}
                    >
                        Cancelar
                    </Button>

                    <Button
                        sx={{
                            height: "45px",
                            backgroundColor: '#109010',
                            color: '#FFFFFF',
                            borderRadius: '8px',
                            padding: '10px 32px',
                            textTransform: 'none'
                        }}
                        size="large"
                        variant="contained"
                        onClick={handleAgree}
                    >
                        Estou de acordo
                    </Button>
                </Box>
            </Box>
        </Modal>
    )
}